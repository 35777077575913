.App {
  text-align: center;
}

#main-image {
  margin: 30px auto 0 auto;
  width: 291px;
  height: 200px;
  background-image: url("./images/main-image.png");
  background-size: cover;
}

#first-joinBtn {
  display: block;
  margin: 30px auto 0 auto;
  color: white;
  width: 314px;
  height: 77px;
  background: #ad002b;
  border: 1px solid #000000;
  border-radius: 50px;
}

#first-joinBtn > p {
  margin: 0;
}

#first-joinBtn > p:first-child {
  margin-top: 14px;
  font-size: 18px;
  font-weight: bold;
  line-height: 22px;
}

#first-joinBtn > p:last-child {
  margin-top: 6px;
  font-size: 14px;
  font-weight: 300;
  line-height: 18px;
}

#read-more {
  margin-top: 20px;
  font-size: 15px;
  line-height: 18px;
}

.section-title {
  font-size: 28px;
  font-weight: 800;
  line-height: 40px;
}

.section-content {
  margin-top: 8px;
  font-size: 17px;
  font-weight: 400;
  line-height: 28px;
}

#welcome {
  margin-top: 90px;
}

#welcome > div:nth-child(3) {
  margin-top: 20px;
  font-size: 17px;
  font-weight: 600;
  line-height: 24px;
  color: #ad002b;
}

#daily-match {
  margin-top: 110px;
}

#daily-match > div:nth-child(1) {
  margin: 30px auto 30px auto;
  width: 281px;
  height: 220px;
  background-image: url("./images/daily-match.png");
  background-size: cover;
}

#simple-easy {
  margin-top: 90px;
}

#simple-easy > div:nth-child(1) {
  margin: 30px auto 30px auto;
  width: 149px;
  height: 309px;
  background-image: url("./images/simple-easy.png");
  background-size: cover;
}

#straight-forward {
  margin-top: 90px;
}

#straight-forward > div:nth-child(1) {
  margin: 30px auto 30px auto;
  width: 149px;
  height: 309px;
  background-image: url("./images/straight-forward.png");
  background-size: cover;
}

#lgbt-friendly {
  margin-top: 90px;
}

#lgbt-friendly > div:nth-child(1) {
  margin: 30px auto 30px auto;
  width: 279px;
  height: 270px;
  background-image: url("./images/lgbt-friendly.png");
  background-size: cover;
}

#footer {
  padding: 0;
  margin-top: 80px;
}

#available {
  background-color: #f1f1f1;
  height: 200px;
  padding: 40px 0 0 0;
}

#available > a:last-child {
  display: block;
  margin: 30px auto 0 auto;
  color: white;
  width: 228px;
  height: 48px;
  background: #ad002b;
  border: 1px solid #000000;
  border-radius: 50px;
  font-weight: 600;
  line-height: 48px;
  text-decoration: none;
}

#footer > div:last-child {
  margin: 30px 0;
  font-size: 14px;
}
